import React from 'react';

const Background: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="overflow-hidden relative w-screen h-screen bg-cover bg-center bg-no-repeat bg-background-pattern">
      {children}
    </div>
  );
};

export default Background;
