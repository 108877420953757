import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Referral {
  username: string;
  total_points: number;
}

interface FriendsProps {
  userId: string | null;
  username: string | null;
}

const Friends: React.FC<FriendsProps> = ({ userId, username }) => {
  const [referrals, setReferrals] = useState<Referral[]>([]);
  const [referralLink, setReferralLink] = useState<string>('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    if (userId === null || username === null) {
      setError('User ID or username not provided');
      setLoading(false);
      return;
    }
    axios.get<Referral[]>(`https://goldmining.fun:8001/api/user/${username}/referral-points`)
    /*axios.get<Referral[]>(`http://127.0.0.1:5000/api/user/${username}/referral-points`)*/ // Ссылка для разработки
      .then(response => {
        setReferrals(response.data);
        return axios.get<{ referral_link: string }>(`https://goldmining.fun:8001/api/user/${userId}`); 
        /*return axios.get<{ referral_link: string }>(`http://127.0.0.1:5000/api/user/${userId}`); */
      })
      .then(response => {
        setReferralLink(response.data.referral_link);
        setLoading(false);
      })
      .catch(error => {
        setError('Error fetching data');
        setLoading(false);
      });
  }, [username, userId]);

  const handleCopyLink = () => {
    navigator.clipboard.writeText(referralLink)
      .then(() => {
        alert('Referral link copied to clipboard!');
      })
      .catch(err => {
        console.error('Failed to copy referral link: ', err);
      });
  };

  if (loading) return <div className="h-screen flex items-center justify-center text-red-600 font-bold">Loading...</div>;
  if (error) return <div className="h-screen flex items-center justify-center text-red-500">{error}</div>;

  return (
    <div className="flex flex-col items-center justify-start h-full p-4 mt-8">
      <h1 className="text-2xl font-bold mb-4 text-white">Friends</h1>
      <p className="text-center mb-10 text-white">Share your referral link with friends and earn rewards! earn 25% of their total income</p>
      <div className="w-full max-w-md flex items-center bg-gray-200 p-2 rounded-lg">
        <input
          type="text"
          readOnly
          value={referralLink}
          className="flex-grow p-2 bg-gray-200 border-none focus:outline-none"
        />
        <button
          onClick={handleCopyLink}
          className="ml-2 px-4 py-2 bg-gradient-to-br from-[#6025F5] to-[#FF5555] text-white rounded-lg hover:shadow-lg transition duration-200"
        >
          Copy
        </button>
      </div>
      <div className="mt-3 space-y-2 overflow-y-auto h-80">
        <div className="w-full flex flex-col items-center mt-2">
          <p className="text-center mb-5 mt-5 text-white font-bold text-2xl">The list of your referrals</p>
          {referrals.map((referral, index) => (
            <div key={index} className="p-2 border-b border-gray-300 w-full text-center font-bold text-white">
              {referral.username}: {referral.total_points.toFixed(3)} usdt
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Friends;
