import React, { useState, useEffect } from 'react';
import axios from 'axios';

interface Link {
  id: number;
  link: string;
  title: string;
}

interface UpgradesProps {
  user: {
    user_id: number;
  };
  updatePoints: (points: number) => void; // Пропс для обновления очков
}

const Upgrades: React.FC<UpgradesProps> = ({ user, updatePoints }) => {
  const [links, setLinks] = useState<Link[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [subscriptions, setSubscriptions] = useState<{ [key: number]: boolean }>({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [linksResponse, balanceResponse] = await Promise.all([
          /*
          axios.get('http://127.0.0.1:5000/api/channel-links'),
          axios.get(`http://127.0.0.1:5000/api/get-balance/${user.user_id}`)  */

        
          axios.get('https://goldmining.fun:8001/api/channel-links'),
          axios.get(`https://goldmining.fun:8001/api/get-balance/${user.user_id}`) 

        /*заменить при деплое*/

        ]);

        setLinks(linksResponse.data);
        updatePoints(balanceResponse.data.points);
      } catch (error) {
        console.error('Error fetching data:', error);
        setError('Error fetching data');
      } finally {
        setLoading(false);
      }
    };

    fetchData();

    const savedSubscriptions = localStorage.getItem('subscriptions');
    if (savedSubscriptions) {
      setSubscriptions(JSON.parse(savedSubscriptions));
    }
  }, [user.user_id, updatePoints]);

  const checkSubscription = async (linkId: number) => {
    try {
      if (!user.user_id) {
        console.error('User ID is not available');
        return;
      } 

       /*const response = await axios.get(`http://127.0.0.1:5000/api/check-subscription/${user.user_id}/${linkId}`);*/
      const response = await axios.get(`https://goldmining.fun:8001/api/check-subscription/${user.user_id}/${linkId}`);  /*заменить при деплое*/
      console.log('Subscription check response:', response.data); // Логируем ответ от API
      /* const newSubscriptions = { ...subscriptions, [linkId]: response.data.subscribed };
      setSubscriptions(newSubscriptions);
      localStorage.setItem('subscriptions', JSON.stringify(newSubscriptions));*/

      if (response.data.subscribed) {

        const newSubscriptions = { ...subscriptions, [linkId]: response.data.subscribed };
        setSubscriptions(newSubscriptions);
        localStorage.setItem('subscriptions', JSON.stringify(newSubscriptions));
        await rewardUser(user.user_id); // Начисление награды только при успешной проверке

      } else {
        alert('Проверка не пройдена');
        setSubscriptions(prev => ({ ...prev, [linkId]: false }));
      }

    } catch (error) {
      console.error('Error checking subscription:', error);
      setSubscriptions(prev => ({ ...prev, [linkId]: false }));
    }
  };

  const handleButtonClick = (linkId: number) => {
    console.log(`Button clicked for link ID: ${linkId}, User ID: ${user.user_id}`);
    checkSubscription(linkId);
  };

  const rewardUser = async (userId: number) => {
    try {
      /*const response = await axios.post('http://127.0.0.1:5000/api/reward-user', { user_id: userId });*/
      const response = await axios.post('https://goldmining.fun:8001/api/reward-user', { user_id: userId }); /*заменить при деплое*/
      console.log('Reward user response:', response.data);
      /* const balanceResponse = await axios.get(`http://127.0.0.1:5000/api/get-balance/${user.user_id}`); */
      const balanceResponse = await axios.get(`https://goldmining.fun:8001/api/get-balance/${user.user_id}`);  /*заменить при деплое*/
      updatePoints(balanceResponse.data.points);
    } catch (error) {
      console.error('Error rewarding user:', error);
    }
  };

  return (
    <div className="p-2 mt-4">
      <h1 className="text-2xl mb-4 text-center text-white font-bold">UPGRADES</h1>
      <p className="mb-8 text-center text-white font-bold overflow-y-auto">Complete tasks to get rewards</p>
      {error && <div className="text-red-500 mt-2">{error}</div>}
      {loading ? (
        <div>Loading...</div>
      ) : (
        

        <ul className="mt-1 space-y-2">
          <p className="mb-4 text-center text-white">Subscribe to the channel and get 0.05 usdt each</p>
          <div className="mt-1 space-y-2  overflow-y-auto h-60"> {/* Контейнер со скроллом и фиксированной высотой */}
          {links.map(link => (
            <li key={link.id} className="flex flex-wrap justify-between items-center p-2 rounded-xl shadow-md">
              <a
                href={link.link}
                className="flex-1 rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-4 py-2 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#6025F5]/50 mx-2 text-center"
                target="_blank"
                rel="noopener noreferrer"
              >
                {link.title}
              </a>
              <button
                onClick={() => handleButtonClick(link.id)}
                disabled={subscriptions[link.id]} // Кнопка отключается при подписке
                className={`flex-1 p-2 rounded-xl text-center font-bold m-2 
                  ${subscriptions[link.id] ? 'bg-gray-800 text-gray-300 cursor-not-allowed' : 'bg-red-500 text-white'}`}
              >
                {subscriptions[link.id] ? 'Done' : 'Check'}
              </button>
            </li>
             
          ))}
          </div>
          <p className="mb-4 text-center text-white">Other task</p>
         
        </ul>
        
      )}
    </div>
  );
};

export default Upgrades;
