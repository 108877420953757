import React, { useState, useEffect } from 'react';
import axios, { AxiosError } from 'axios';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Background from './Background';
import MainMenu from './MainMenu';
import Friends from './Friends';
import Upgrades from './Upgrades';

interface User {
  id: number;
  user_id: number;
  username: string;
  points: number;
  last_reward_time: number;
  referral_link: string;
}

const App: React.FC = () => {
  const [user, setUser] = useState<User | null>(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [rewardError, setRewardError] = useState<string | null>(null);
  const [isClaiming, setIsClaiming] = useState(false);
  const [timeLeft, setTimeLeft] = useState<number>(0);

  useEffect(() => {
    const tg = (window as any).Telegram?.WebApp;

    if (tg?.initDataUnsafe?.user?.id) {
      const userId = tg.initDataUnsafe.user.id;

      axios.get<User>(`https://goldmining.fun:8001/api/user/${userId}`)
      /*axios.get<User>(`http://127.0.0.1:5000/api/user/${userId}`)*/
        .then(response => {
          setUser(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching user data:', error);
          setError('Error fetching user data');
          setLoading(false);
        });
    } else {
      const mockUserId = 6931569690;

      axios.get<User>(`https://goldmining.fun:8001/api/user/${mockUserId}`)
      /*axios.get<User>(`http://127.0.0.1:5000/api/user/${mockUserId}`)*/
        .then(response => {
          setUser(response.data);
          setLoading(false);
        })
        .catch(error => {
          console.error('Error fetching mock user data:', error);
          setError('Error fetching user data');
          setLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (user) {
      const intervalId = setInterval(() => {
        const currentTime = Date.now();
        const lastRewardTime = user.last_reward_time ? user.last_reward_time * 1000 : 0;
        const timeDiff = 4 * 3600 * 1000 - (currentTime - lastRewardTime);
        setTimeLeft(timeDiff > 0 ? timeDiff : 0);
      }, 1000);

      return () => clearInterval(intervalId);
    }
  }, [user]);

  const handleClaimReward = async (): Promise<void> => {
    if (!user) return;
    setIsClaiming(true);
    setRewardError(null);

    try {
      const response = await axios.post(`https://goldmining.fun:8001/api/user/${user.user_id}/reward`);
      /*const response = await axios.post(`http://127.0.0.1:5000/api/user/${user.user_id}/reward`); */
      const currentTime = Date.now();
      setUser(prevUser => prevUser ? { ...prevUser, points: response.data.new_points, last_reward_time: currentTime / 1000 } : null);
      setIsClaiming(false);
      setTimeLeft(4 * 3600 * 1000);
    } catch (error) {
      // Обработка ошибок с явным приведением типа
      const axiosError = error as AxiosError;
      console.error('Error claiming reward:', axiosError);
      
      setIsClaiming(false);
    }
  };

  const formatTimeLeft = (milliseconds: number) => {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const seconds = totalSeconds % 60;
    return `${hours}:${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  if (loading) return <div className="h-screen flex items-center justify-center">Loading...</div>;
  if (error) return <div className="h-screen flex items-center justify-center text-red-500">{error}</div>;

  const canClaimReward = timeLeft === 0;

  return (
    <Router>
      <div className="h-screen flex flex-col overflow-hidden">
        <Background>
          {user && (
            <Routes>
              <Route path="/" element={
                <MainMenu
                  user={user}
                  timeLeft={timeLeft}
                  isClaiming={isClaiming}
                  canClaimReward={canClaimReward}
                  handleClaimReward={handleClaimReward}
                  formatTimeLeft={formatTimeLeft}
                  rewardError={rewardError}
                  updatePoints={(points) => setUser(prev => prev ? { ...prev, points } : null)}
                />
              } />
              <Route path="/friends" element={<Friends userId={user.user_id.toString()} username={user.username} />} />
              <Route
                path="/upgrades"
                element={
                  <Upgrades
                    user={user}
                    updatePoints={(points) => setUser(prev => prev ? { ...prev, points } : null)}
                  />
                }
              />
            </Routes>
          )}
          <div className="w-full fixed bottom-2 bg-white bg-opacity-50 backdrop-blur-lg p-2 flex justify-around">
            <Link to="/friends" className="flex-1 rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-4 py-2 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#6025F5]/50 mx-2 text-center">Friends</Link>
            <Link to="/" className="flex-1 rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-4 py-2 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#6025F5]/50 mx-2 text-center">Main</Link>
            <Link to="/upgrades" className="flex-1 rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-4 py-2 text-base font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#6025F5]/50 mx-2 text-center">Upgrades</Link>
          </div>
        </Background>
      </div>
    </Router>
  );
};

export default App;
