import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';

interface User {
  id: number;
  username: string;
  points: number;
  last_reward_time: number;
  user_id: number;
}

interface MainMenuProps {
  user: User;
  timeLeft: number;
  isClaiming: boolean;
  canClaimReward: boolean;
  handleClaimReward: () => Promise<void>;
  formatTimeLeft: (milliseconds: number) => string;
  rewardError: string | null;
  updatePoints: (points: number) => void; // Add updatePoints prop
}

const MainMenu: React.FC<MainMenuProps> = ({ user, timeLeft, isClaiming, canClaimReward, handleClaimReward, formatTimeLeft, rewardError, updatePoints }) => {
  const [points, setPoints] = useState(user.points);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  const fetchBalance = async () => {
    setIsLoading(true);
    setError(null);
    try {
       /*const response = await axios.get(`http://127.0.0.1:5000/api/get-balance/${user.user_id}`);*/
      const response = await axios.get(`https://goldmining.fun:8001/api/get-balance/${user.user_id}`); /*заменить при деплое*/
      const roundedPoints = response.data.points.toFixed(3); // Round points to three decimal places
      setPoints(parseFloat(roundedPoints));
      updatePoints(parseFloat(roundedPoints));
    } catch (error) {
      console.error('Error fetching balance:', error);
      setError('Error fetching balance');
    } finally {
      setIsLoading(false);
    }
  };

  const handleNavigation = (path: string) => {
    navigate(path, { state: { points } });
  };

  const handleClick = async () => {
    if (canClaimReward) {
      try {
         await handleClaimReward();
         await fetchBalance(); // Fetch the updated balance after claiming the reward
      } catch (error) {
        console.error('Error handling reward:', error);
      }
    }
  };

  return (
    <div className="flex flex-col justify-between h-full items-center mt-4">
      <div className="p-4 items-center text-center mt-5">
        <p className="text-2xl mt-5 font-bold text-white">Welcome, {user.username}!</p>
        <h1 className="text-4xl font-bold mb-3 mt-5 text-white">Usdt: {points.toFixed(3)}</h1>
      </div>
      <div className="w-full flex justify-center mb-2 mt-12">
      <button
          className={`rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-16 py-6 text-3xl font-medium text-white transition duration-200 hover:shadow-lg hover:shadow-[#6025F5]/50 mb-4 ${!canClaimReward ? 'opacity-50 cursor-not-allowed' : ''}`}
          onClick={handleClick} // Use handleClick instead of handleClaimReward
          disabled={!canClaimReward}
        >
          {canClaimReward ? 'Claim Reward' : formatTimeLeft(timeLeft)}
        </button>
        
      </div>

     

      <div className="w-full flex justify-center mb-32 mt-2">
      <button 
         className="rounded-xl bg-gradient-to-br from-[#6025F5] to-[#FF5555] px-8 py-4 text-2xl font-medium text-white  mb-4">
          Game ...Soon
        </button>

       
        
      </div> 
     
      
      
      {rewardError && <p className="text-red-500 mt-2">{rewardError}</p>}

     

    </div>
  );
};

export default MainMenu;
